import withSuspense from '../../../../common/hocs/withSuspense';
import { connect } from '../../../../storeConfig';
import { GlobalState } from '../../../../rootReducer';
import InvoiceHeader, { DispatchProps, Props } from './InvoiceHeader';
import { showAddOrEditSupplierModal } from '../../../../components/EditSupplierModal/EditSupplierModalActions';
import { selectAddSupplierLoadable, selectUpdateSupplierLoadable } from '../../../../components/EditSupplierModal/EditSupplierModalReducer';
import { selectCurrentCompanySettings } from '../../../../common/company/CompanySelectors';
import { getCurrentCompanyName } from '../../../../common/user/UserSelectors';
import { selectInvoiceDataLoadable } from '../../components/invoice-slider/InvoiceSliderReducers';
import { setTransactionRowsIsLoading } from '../invoice-transaction-rows/TransactionRowsActions';

const mapStateToProps = (state: GlobalState): Pick<Props, 'currentCompanyName' | 'currentCompanySettings' | 'invoiceSliderDataLoadable' | 'addSupplierLoadable' | 'updateSupplierLoadable'> => ({
    currentCompanyName: getCurrentCompanyName(state),
    currentCompanySettings: selectCurrentCompanySettings(state),
    invoiceSliderDataLoadable: selectInvoiceDataLoadable(state),
    addSupplierLoadable: selectAddSupplierLoadable(state),
    updateSupplierLoadable: selectUpdateSupplierLoadable(state),
});

const mapDispatchToProps: DispatchProps = {
    showAddOrEditSupplierModal,
    setTransactionRowsIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(InvoiceHeader));
