import { createAction } from 'redux-actions';
import { batch } from 'react-redux';
import { saveAs } from 'file-saver';
import { getCurrentUser } from '../../../../common/user/UserSelectors';

import i18nInstance from '../../../../i18n';
import { DispatchThunk } from '../../../../storeConfig';
import { GlobalState } from '../../../../rootReducer';
import api from '../../../../services/ApiServices';

import { AccountDTO, BaseSearch, PagedListContainer, TransactionRowDimensionDTO, TransactionRowDTO, TransactionRowsExtendedInfoDTO } from '../../../../services/types/ApiTypes';
import { createRequest, selectTransactionRowsListLoadable } from './TransactionRowsReducers';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../../../common/utils/LoadableData';
import { notify } from '../../../../common/utils/notify';
import { ProductItemType } from './components/ExpandedRowContent/ExpandedRowContentType';
import { TransactionRowNewObject, TransactionRowsExportType, TransactionRowTableObject } from './TransactionRowsTypes';

const ns = 'invoice/invoice-transaction-rows/';

export const getInvoiceTransactionRowsLoadable = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<TransactionRowDTO>>(`${ns}ALL_TRANSACTION_ROWS`);
export const getTransactionRowsExtendedInfoList = loadableDataActions<{ invoiceId: number }, TransactionRowsExtendedInfoDTO[]>(`${ns}GET_ALL_TRANSACTION_ROWS_EXT`);
export const exportTransactionRowsActions = loadableDataActions(`${ns}EXPORT_TRANSACTION_ROWS`);
export const updateRow = createAction<TransactionRowDTO>(`${ns}UPDATE_ROW`);
export const addRow = createAction<TransactionRowDTO>(`${ns}ADD_ROW`);
export const setTransactionRowDetailsData = createAction<TransactionRowDTO | null>(`${ns}SET_TRANSACTION_ROW_DETAILS_DATA`);
export const setTransactionRowDetailsModalOpenState = createAction<boolean>(`${ns}SET_TRANSACTION_ROW_DETAILS_MODAL_OPEN_STATE`);
export const setTransactionRowDetailsIsUpdated = createAction<boolean>(`${ns}SET_TRANSACTION_ROW_DETAILS_UPDATED`);
export const setTransactionRowsIsLoading = createAction<boolean>(`${ns}SET_TRANSACTION_ROWS_IS_LOADING`);
export const setTransactionRowDetailsIsUpdating = createAction<boolean>(`${ns}SET_TRANSACTION_ROW_DETAILS_IS_UPDATING`);

export const getInvoiceTransactionRowsExtendedInfoList = (invoiceId: number) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(getTransactionRowsExtendedInfoList.request({ invoiceId }));
            const response = await api.invoice.getTransactionRowsExtendedInfoList(invoiceId);
            dispatch(getTransactionRowsExtendedInfoList.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getTransactionRowsExtendedInfoList.error(e));
        }
    };
};

export const getTransactionRowsList = (id: number, page?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const searchParams = createRequest();
        searchParams.PagingOptions.Page = page || 1;
        try {
            dispatch(getInvoiceTransactionRowsLoadable.request(searchParams));
            const response = await api.invoice.getInvoiceTransactionRowsByInvoiceId(id, searchParams);
            if (response?.status === 200) {
                if (page !== 1) {
                    response.data.Items = [...getState().invoiceTransactionRows.transactionRowsLoadable.payload.Items, ...response.data.Items];
                }
                dispatch(
                    getInvoiceTransactionRowsLoadable.success({
                        request: searchParams,
                        result: response.data,
                    }),
                );
                if (page === 1) {
                    dispatch(getInvoiceTransactionRowsExtendedInfoList(id));
                }
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getInvoiceTransactionRowsLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const updateCustomField = (customFieldItem: TransactionRowDimensionDTO, row: TransactionRowDTO) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (customFieldItem) {
            try {
                let response;
                if (customFieldItem?.DimensionId) {
                    response = await api.invoice.updateCustomFieldWithRelations(row.Id, customFieldItem);
                } else {
                    response = await api.invoice.updateCustomField(row.Id, customFieldItem);
                }
                if (response?.status === 200) {
                    const newRow = { ...row };
                    const user = getCurrentUser(getState());

                    const foundIndex = newRow.TransactionRowsDimensions.findIndex((x) => x.CustomCostObjectiveId === customFieldItem.CustomCostObjectiveId);

                    newRow.TransactionRowsDimensions[foundIndex] = { ...customFieldItem, ModifiedBy: user.GroupMemberId };
                    notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                    dispatch(updateRow(newRow));
                } else {
                    notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
                }
            } catch (e) {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        }
    };
};

export const updateAccount = (account: AccountDTO, row: TransactionRowDTO) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const response = await api.invoice.updateAccount(account?.Id, row.Id);
            if (response?.status === 200) {
                const user = getCurrentUser(getState());
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                const newRow = { ...row };
                newRow.Account = account || null;
                newRow.TransactionRowChangeSource = { ...newRow.TransactionRowChangeSource, Account: user.GroupMemberId };
                dispatch(updateRow(newRow));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const updateTransactionRowProductItemBuyer = (productItem: ProductItemType, row: TransactionRowDTO) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const id = productItem?.Id || null;
            const response = await api.invoice.updateTransactionRowProductItemBuyerId(row.Id, id);
            if (response?.status === 200) {
                const user = getCurrentUser(getState());
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                const newRow = { ...row };
                newRow.BuyerProductId = id;
                newRow.BuyerProductName = productItem?.Name || null;
                newRow.TransactionRowChangeSource = { ...newRow.TransactionRowChangeSource, ProductCode: user.GroupMemberId };
                dispatch(updateRow(newRow));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const updateVatCode = (row: TransactionRowDTO, reloadInvoiceData: (invoiceId: number) => void) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const response = await api.invoice.updateVatCode(row.InvoiceId, row);
            if (response?.status === 200) {
                const user = getCurrentUser(getState());
                const newRow = { ...row };
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                newRow.TransactionRowChangeSource = { ...newRow.TransactionRowChangeSource, VatCode: user.GroupMemberId };
                dispatch(updateRow(newRow));
                reloadInvoiceData(row.InvoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
};

export const openTransactionRowDetails = (data: TransactionRowTableObject | null) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const transactionRowsLoadable = selectTransactionRowsListLoadable(getState());
        if (!transactionRowsLoadable) {
            return;
        }
        const transactionRow = transactionRowsLoadable.payload.Items.find((row) => row.Id === data?.Id);
        if (!transactionRow) {
            return;
        }
        batch(() => {
            dispatch(setTransactionRowDetailsData(transactionRow));
            dispatch(setTransactionRowDetailsIsUpdated(false));
            dispatch(setTransactionRowDetailsModalOpenState(true));
        });
    };
};

export const updateTransactionRow = (data: TransactionRowDTO, isAccountingDateChanged: boolean) => {
    return async (dispatch: DispatchThunk) => {
        dispatch(setTransactionRowDetailsIsUpdating(true));
        let updatedTransactionRow: TransactionRowDTO | null = null;
        try {
            const updMainFIelds = await api.invoice.updateTransactionRow(data);
            updatedTransactionRow = updMainFIelds.data;
            if (isAccountingDateChanged) {
                const updatedDate = await api.invoice.updateDate(data.Id, data.AccountingDate?.toString());
                updatedTransactionRow.AccountingDate = updatedDate.data.AccountingDate;
            }
            batch(() => {
                dispatch(setTransactionRowDetailsIsUpdated(true));
                dispatch(setTransactionRowDetailsIsUpdating(false));
                dispatch(setTransactionRowDetailsData(updatedTransactionRow));
            });

            notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
            return Promise.resolve({ message: 'ERRROR' });
        } catch (e) {
            dispatch(setTransactionRowDetailsIsUpdated(false));
            console.error('Error in updating transaction row', e);
            return Promise.reject(e);
        }
    };
};

export const addNewTransactionRow = (row: TransactionRowDTO | TransactionRowNewObject, reloadInvoiceData: (invoiceId: number) => void) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(setTransactionRowsIsLoading(true));
            const repsponse = await api.invoice.addTransactionRow(row);
            dispatch(addRow(repsponse.data));
            reloadInvoiceData(row.InvoiceId);
        } catch (e) {
            console.error(e);
            dispatch(setTransactionRowsIsLoading(false));
        }
    };
};

export function exportTransactionRows(invoiceId: number, exportType: TransactionRowsExportType) {
    return async (dispatch: DispatchThunk) => {
        dispatch(exportTransactionRowsActions.request(undefined));
        try {
            const isDefaultExcelExport = exportType === 'Excel';
            const response = isDefaultExcelExport ? await api.invoice.exportTransactionRowsToXls(invoiceId) : await api.invoice.exportTransactionRowsWithFormulasToXls(invoiceId);
            if (response.status === 200) {
                const file = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
                const fileName = isDefaultExcelExport ? `Invoice_${invoiceId}_transaction-rows.xlsx` : `Invoice_${invoiceId}_transaction-rows-with-formulas.xlsx`;
                saveAs(file, fileName);
                dispatch(exportTransactionRowsActions.success(fileName));
            } else if (response.status === 400 && response.data) {
                response.data.text().then(function(response: any) {
                    const data = JSON.parse(response);
                    notify.error(data.ErrorCode ? i18nInstance.t(data.ErrorCode.replace('{0}', data.FieldName || '')) : data.Message);
                });
            }
        } catch (e) {
            console.error(e);
            dispatch(exportTransactionRowsActions.error(e));
        }
    };
}

export function combineAllByVat(invoiceId: number, reloadInvoiceData: (invoiceId: number) => void) {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.invoice.mergeAllAccountingRows(invoiceId);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                reloadInvoiceData(invoiceId);
                dispatch(getTransactionRowsList(invoiceId, 1));
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}

export function combineAllByMeta(invoiceId: number, metaInfo: TransactionRowsExtendedInfoDTO, reloadInvoiceData: (invoiceId: number) => void) {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.invoice.mergeAccountingRowsByTransactionRowExtension(invoiceId, metaInfo);
            if (response?.status === 200) {
                notify.success(i18nInstance.t('views.invoice.partials.invoiceRows.Saving_successful'), i18nInstance.t('views.invoice.partials.invoiceRows.Success'));
                dispatch(getTransactionRowsList(invoiceId, 1));
                reloadInvoiceData(invoiceId);
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('controller.invoiceConfirmationController.Error'));
        }
    };
}
